// src/components/CartContext.tsx
import React, { createContext, useContext, useState, ReactNode } from "react";
import { Meal } from "../interfaces";

interface CartItem extends Meal {
  quantity: number;
  type: "pricePerTray" | "pricePerLb" | "pricePerPlate";
}

interface CartContextType {
  cart: CartItem[];
  addToCart: (meal: CartItem) => void;
  removeFromCart: (id: string, type: CartItem["type"]) => void;
  updateCartQuantity: (id: string, type: CartItem["type"], qty: number) => void;
  clearCart: () => void;
}

const CartContext = createContext<CartContextType | undefined>(undefined);

export const CartProvider = ({ children }: { children: ReactNode }) => {
  const [cart, setCart] = useState<CartItem[]>([]);

  const addToCart = (meal: CartItem) => {
    setCart((prevCart) => [...prevCart, meal]);
  };

  const removeFromCart = (id: string, type: CartItem["type"]) => {
    setCart((prevCart) =>
      prevCart.filter((item) => item.id !== id || item.type !== type)
    );
  };

  const clearCart = () => {
    setCart([]);
  };

  const updateCartQuantity = (
    id: string,
    type: CartItem["type"],
    qty: number
  ) => {
    console.log({ id, type, qty });
    // setCart((prevCart) =>
    //   prevCart.filter((item) => item.id !== id || item.type !== type)
    // );
  };

  return (
    <CartContext.Provider
      value={{ cart, addToCart, removeFromCart, clearCart, updateCartQuantity }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};
