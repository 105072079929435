import React from "react";
import menuData, { Choice } from "./menuData"; // Import the JSON data
import "./Menu.css"; // Custom CSS file for styling

const MenuPage = ({ isAdmin = false }: { isAdmin?: boolean }) => {
  return (
    <div className="menu">
      <h1 className="menu-title">Our Menu</h1>

      {/* Map over each category */}
      {Object.keys(menuData).map((category) => (
        <Section key={category} title={capitalize(category)}>
          {menuData[
            category as
              | "appetizers"
              | "entrees"
              | "curry Dishes"
              | "side Dishes"
              | "specialty Dishes"
          ].map((item, index) => (
            <MenuItem
              key={index}
              name={item.name}
              description={item.description}
              price={item.price}
              choices={item.choices}
            />
          ))}
        </Section>
      ))}
    </div>
  );
};

const Section = ({
  title,
  children,
}: {
  title: string;
  children: JSX.Element | JSX.Element[];
}) => (
  <div className="menu-section">
    <h2 className="menu-section-title">{title}</h2>
    <div className="menu-items">{children}</div>
  </div>
);

const MenuItem = ({
  name,
  description,
  price,
  choices,
}: {
  name: string;
  description: string;
  price: string | undefined;
  choices: Choice[] | undefined;
}) => (
  <div className="menu-item">
    <h3 className="menu-item-name">
      {name} <span className="menu-item-price">{price}</span>
    </h3>
    <p className="menu-item-description">{description}</p>
    {choices && (
      <ul>
        {choices.map((c) => (
          <li key={c.name}>
            {/* <div>{c.name}</div> */}
            <p className="menu-item-description">
              <span style={{ fontWeight: "bold", width: "100%" }}>
                {c.name}
              </span>
              {"  -  "}
              <span>{c.price}</span>
            </p>
          </li>
        ))}
      </ul>
    )}
  </div>
);

// Helper function to capitalize section titles
const capitalize = (text: string) =>
  text.charAt(0).toUpperCase() + text.slice(1);

export default MenuPage;
