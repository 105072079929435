import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

import { getAnalytics } from "firebase/analytics";
import { createTheme } from "@mui/material";
import { Meal } from "./interfaces";
import {
  DocumentData,
  QuerySnapshot,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  orderBy,
  query,
} from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCQSZnnyaR1HA6aOHDFvSxboFrh1aFSE-0",
  authDomain: "kesscatering.firebaseapp.com",
  projectId: "kesscatering",
  storageBucket: "kesscatering.appspot.com",
  messagingSenderId: "827843142705",
  appId: "1:827843142705:web:3dacfcb7bdec7d691c6110",
  measurementId: "G-6Y4JM736L4",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

export const theme = createTheme({
  palette: {
    mode: "light",
    //background: "black",
    primary: {
      main: "#FFD580", // Custom primary color
    },
    secondary: {
      main: "#dc004e", // Custom secondary color
    },
  },

  typography: {
    h1: {
      fontSize: "2.5rem",
    },
    body1: {
      fontSize: "1rem",
    },
  },
});

export const getMenu = async () => {
  try {
    const mealsRef = collection(db, "meals");
    const mealsQuery = query(mealsRef, orderBy("createdOn", "desc"));
    const querySnapshot = await getDocs(mealsQuery);

    const meals: Meal[] = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    })) as Meal[];

    return meals;
  } catch (error) {
    return [];
  }
};

export const getMealById = async (mealId: string) => {
  try {
    const mealDocRef = doc(db, "meals", mealId);
    const mealDoc = await getDoc(mealDocRef);

    if (mealDoc.exists()) {
      return { id: mealDoc.id, ...mealDoc.data() } as Meal;
    } else {
      console.error("No such document!");
      return null;
    }
  } catch (error) {
    console.error("Error getting document:", error);
    throw error; // Rethrow error to handle it in the calling function if needed
  }
};

export const USDollar = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
});

export const formatOrderDetailsToHTML = (orderDetails: any) => {
  const {
    name,
    email,
    phone,
    deliveryMethod,
    date,
    time,
    address,
    totalCost,
    cart,
  } = orderDetails;

  const formatCartItems = (cart: any) => {
    return cart
      .map(
        (item: any) => `
      <tr>
        <td>${item.name}</td>
        <td>${item.description}</td>
        <td>${item.quantity}</td>
        <td>${USDollar.format(item[item.type])}</td>
        <td>${USDollar.format(item.quantity * item[item.type])}</td>
      </tr>
    `
      )
      .join("");
  };

  return `
    <html>
      <head>
        <style>
          table {
            width: 100%;
            border-collapse: collapse;
          }
          table, th, td {
            border: 1px solid black;
          }
          th, td {
            padding: 10px;
            text-align: left;
          }
          th {
            background-color: #f2f2f2;
          }
        </style>
      </head>
      <body>
        <h2>Order Details</h2>
        <p><strong>Name:</strong> ${name}</p>
        <p><strong>Email:</strong> ${email}</p>
        <p><strong>Phone:</strong> ${phone}</p>
        <p><strong>Delivery Method:</strong> ${deliveryMethod}</p>
        <p><strong>Date:</strong> ${date}</p>
        <p><strong>Time:</strong> ${time}</p>
        ${
          deliveryMethod === "delivery"
            ? `
          <p><strong>Address:</strong></p>
          <p>${address.line1}</p>
          <p>${address.city}, ${address.state} ${address.zip}</p>
        `
            : ""
        }
        <h3>Cart Items</h3>
        <table>
          <thead>
            <tr>
              <th>Item Name</th>
              <th>Description</th>
              <th>Quantity</th>
              <th>Unit Price</th>
              <th>Total Price</th>
            </tr>
          </thead>
          <tbody>
            ${formatCartItems(cart)}
          </tbody>
        </table>
        <h3>Total Cost: ${USDollar.format(totalCost)}</h3>
      </body>
    </html>
  `;
};
