// Footer.tsx
import React from "react";
import { Container, Box, Typography, Link, Grid } from "@mui/material";

const Footer: React.FC = () => {
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: "auto",
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[200]
            : theme.palette.grey[800],
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              Contact us
            </Typography>
            <Link
              href="mailto:kesienaevwierekegba@gmail.com"
              variant="body2"
              color="text.secondary"
            >
              kesienaevwierekegba@gmail.com
            </Link>
            <br />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              Socials
            </Typography>
            <Link
              href="https://www.instagram.com/kess_catering_services/"
              variant="body2"
              color="text.secondary"
              target="_blank"
              rel="noopener"
            >
              Instagram
            </Link>
            <br />
          </Grid>
        </Grid>
        <Box mt={5}>
          <Typography variant="body2" color="text.secondary" align="center">
            {"© "}
            {new Date().getFullYear()}
            {" Kess Kitchen SERVICES, LLC"}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
